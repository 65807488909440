import React from "react";
import image1 from "./resources/image1.jpeg";
import image2 from "./resources/image2.jpeg";
import image4 from "./resources/image4.jpeg";
import image5 from "./resources/image5.jpeg";

export default class ServicesComponent extends React.Component {
    render() {
        const features = [
            { name: 'Deep Tissue', description: 'A massage utilizing a more firm pressure and a more focused approach to tense muscles.' },
            { name: 'Swedish', description: 'A general massage using light to medium pressure, used purely for relaxation.' },
            { name: 'Basic Craniosacral', description: 'Very gentle touch to help the body regulate the central nervous system.' },
            { name: 'Basic Lymphatic', description: 'Manual drainage of the lymphatic pathways.' },
            { name: 'Foot Treatment', description: 'A foot soak, scrub, and massage to soften feet and relax the body.' },
            { name: 'Addons', description: 'Joint mobility, hot stones, or back sugar scrub can be added to any service.'}
        ]

        return (
            <div className="mt-10">
                <div className="grid grid-cols-1 gap-y-8 gap-x-8 desktop:grid-cols-2 desktop:gap-y-8">
                    <div className="desktop:col-span-2">
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 tablet:text-4xl">What I Do...</h2>
                    </div>

                    <dl className="grid grid-cols-1 gap-x-6 gap-y-10 tablet:grid-cols-2 desktop:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="border-t border-gray-200 pt-4">
                                <dt className="font-medium text-gray-900">{feature.name}</dt>
                                <dd className="mt-2 text-sm text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>

                    <div className="grid grid-flow-row-dense grid-cols-2 gap-1 tablet:gap-6">
                        <img src={image1} alt="" className="bg-gray-100 rounded-lg" />
                        <img src={image2} alt="" className="bg-gray-100 rounded-lg row-span-2" />
                        <img src={image5} alt="" className="bg-gray-100 rounded-lg row-span-2" />
                        <img src={image4} alt="" className="bg-gray-100 rounded-lg" />
                    </div>
                </div>
            </div>
        )
    }

}