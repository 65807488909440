import React from "react";

export default class BookNowButton extends React.Component {
    render() {
        return (
            <div className="flex bg-mainGreen rounded-md">
                <a className="text-white font-semibold py-2 px-5 w-full text-center" href="https://booking.setmore.com/scheduleappointment/r69a11659566832167">Book Now</a>
            </div>
        );
    }
}