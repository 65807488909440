import React from "react";
import AboutComponent from "./components/AboutComponent";
import ServicesComponent from "./components/ServicesComponent";
import hero from "./hero.png";

export class HomePage extends React.Component {
    render() {
        return (
            <div className="bg-white max-w-4xl my-5 mx-5 tablet:mx-auto rounded-xl mb-10 shadow-lg">
                <img src={hero} alt="" className="bg-gray-500 rounded-t-xl shadow-md" />
                <div className="p-5 tablet:p-10 desktop:p-14">
                    <AboutComponent />
                    <ServicesComponent />
                </div>
            </div>
        );
    }
}