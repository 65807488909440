import React from "react";
import profile from "./resources/profile.jpeg"
import BookNowButton from "./BookNowButton";

export default class AboutComponent extends React.Component {
    render() {
        return (
            <div className="">
                <div className="flex items-center">
                    <div className="pr-4">
                        <img className="bg-gray-400 rounded-full w-24 tablet:w-36 shadow-md" alt="Profile" src={profile} />
                    </div>

                    <div>
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 tablet:text-4xl">Hello! 👋</h2>
                        <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 tablet:text-4xl">I'm Chayce</h2>
                    </div>

                    <div className="mx-auto hidden tablet:flex">
                        <BookNowButton />
                    </div>
                </div>


                <p className="mt-4 text-gray-500 col-span-2">
                    I graduated from the massage therapy program offered at Bridgerland Applied Technology College. I love that with my education, I am able to customize each massage to the client in order to relax, rehabilitate, or just re-introduce your body to a pain free life.
                </p>

                <div className="mx-auto tablet:hidden pt-10">
                    <BookNowButton />
                </div>
            </div>
        );
    }
}